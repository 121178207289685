.about-card,
.product-step-card,
.option-card {
	background: #f8f8f8;
	height: 100%;
}

.number {
	font-size: 3rem;
	color: $text-color;
	font-weight: 700;
}
.text-number {
	font-size: 2.625rem;
	color: $base-color--dark;
	font-weight: 700;
	min-width: 4rem;
}