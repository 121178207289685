.game-badges {
    .status-p {
        display: inline-block;
        text-decoration: none;
        font-family: 'Inter', sans-serif;
        padding: 0.125rem 0.8rem;
        max-width: fit-content;
        font-weight: 700;
        border: none;
        cursor: pointer;
        white-space: nowrap;
        font-size: .9rem;
        color: $white;
    }
}
