.registration {
	background: url("../../img/form-bg.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 5rem;
	.row {
		//gap: .875rem;
		//margin-bottom: 1.875rem;
	}

    .column {
        gap: .875rem;
        margin-bottom: 1.875rem;
    }
    .login-with-google-btn {
        transition: background-color .3s, box-shadow .3s;
          
        padding: 7px 16px 7px 42px;
        border: none;
        border-radius: 3px;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
        
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
        background-color: white;
        background-repeat: no-repeat;
        background-position: 12px 11px;
        
        &:hover {
          box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
        }
        
        &:active {
          background-color: #eeeeee;
        }
        
        &:focus {
          outline: none;
          box-shadow: 
            0 -1px 0 rgba(0, 0, 0, .04),
            0 2px 4px rgba(0, 0, 0, .25),
            0 0 0 3px #c8dafc;
        }
        
        &:disabled {
          filter: grayscale(100%);
          background-color: #ebebeb;
          box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
          cursor: not-allowed;
        }
      }
    .btn-fb {
        display: inline-block;
        border-radius: 3px;
        text-decoration: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        -webkit-transition: background-color 0.218s, border-color 0.218s,
        box-shadow 0.218s;
        transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    }
    .fb-content,
    .btn-fb,
    .btn-fb .fb-content {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 210px;
        height: 40px;
    }
    .fb-content .logo,
    .btn-fb .logo,
    .btn-fb .fb-content .logo {
        padding: 3px;
        height: inherit;
    }
    
    .fb-content svg,
    .btn-fb svg,
    .btn-fb .fb-content svg {
        width: 18px;
        height: 18px;
    }
    .fb-content p,
    .btn-fb,
    .btn-fb .fb-content p {
        width: 100%;
        width: 220px;
        line-height: 1;
        letter-spacing: 0.21px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
    }
    
    .btn-fb {
        padding-top: 1.5px;
        background: #1877f2;
        background-color: #1877f2;
    }
    .btn-fb:hover {
        box-shadow: 0 0 3px 3px rgba(59, 89, 152, 0.3);
    }
    .btn-fb .fb-content p {
        color: rgba(255, 255, 255, 0.87);
    }
      
      body {
        text-align: center;
        padding-top: 2rem;
      }

    .card-select {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-family: 'Inter', sans-serif;
        background: $base-color-light;
        color: $base-color--dark;
        //padding: 3.5rem 7rem;
        //min-width: 20rem;
        width: 100%;
        padding: 1.5rem 2rem;
        max-width: calc(100vw - 2rem);
        height: 9rem;
        margin: 0;
        font-weight: 700;
        border: none;
        cursor: pointer;
        transition: .3s all;
        font-size: 1.3rem;

        &:hover {
            //background: darken($base-color-light, 9%);
            font-size: 1.5rem;
        }
    }
}

.registration form {
	max-width: 100%;
}
