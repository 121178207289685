form {
    width: 100%;
    max-width: 600px;
	.form-input {
		height: 100%;
	}
    .search {
        width: 100%;
        position: relative;
        .icon-search {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
        }
    }
    .date-custom {
        position: relative;

        &::-webkit-calendar-picker-indicator {
            filter: invert(1);
            //color: transparent;
            //background: none;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }
    }
    input:not([type="checkbox"], [type="radio"]) {
        all: initial;
        font-family: "Inter", sans-serif;
        background: $base-color-light;
        padding: 0.75rem 2.5rem 0.9375rem 1rem;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $base-color;
		&.dropdown-search {
			border: none;
			background: $base-color-dark;
			color: $base-color-light;
			&::placeholder {
				color: $base-color-light;
			}
			@media (max-width:991px) {
				background: $base-color-light;
				color: $base-color-dark;
				&::placeholder {
					color: $base-color-dark;
				}
			}
		}
    }
    textarea {
        all: initial;
        width: 100%;
        box-sizing: border-box;
        color: $base-color-light;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        padding: 0.75rem 2.5rem 0.9375rem 1rem;
        border: 1px solid $base-color-light;
		display: block;
        word-break: break-all;

        &::placeholder {
            color: inherit;
            font-weight: inherit;
        }
    }
}

.search {
    width: 100%;
    max-width: 600px;
    position: relative;

    .search-link {
        &:hover {
            .search-result-title {
                font-weight: bold;
            }
        }
    }
    .search-result-title {
        font-size: 1rem;
        line-height: normal;
        color: $base-color-dark;
        text-transform: uppercase;
        transition: .3s font-weight;
    }
    .search-result-desc {
        text-transform: uppercase;
        font-size: .7rem;
        line-height: normal;
        color: lighten($base-color-dark, 20%);
    }
    .search-result-price {
        text-transform: uppercase;
        font-size: .9rem;
        line-height: normal;
        color: lighten($base-color-dark, 10%);
    }
    .search-img {
        width: 4rem;
        height: 4rem;
        overflow: hidden;
        img {
            max-height: 100%;
            width: 100%;
            max-width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .search-title {
        color: lighten($base-color-dark, 30%);
        text-transform: uppercase;
        font-size: .8rem;
        font-weight: bold;
    }
    .border-bottom-light {
        border-bottom: 1px solid lighten(#b0b0b0, 10%);
    }
    .border-bottom {
        border-bottom: 1px solid #b0b0b0;
    }
    .search-results-wrap {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .search-wrap {
        overflow-y: auto;
        max-height: calc(100vh - 5rem);
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        right: 0;
        z-index: 100;
        background-color: $bg-color-light;
        box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.1);
        -webkit-box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.1);
    }
    .icon-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }
    input:not([type="checkbox"], [type="radio"]) {
        all: initial;
        font-family: "Inter", sans-serif;
        background: $base-color-light;
        padding: 0.75rem 2.5rem 0.9375rem 1rem;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $base-color;
        &.dropdown-search {
            border: none;
            background: $base-color-dark;
            color: $base-color-light;
            &::placeholder {
                color: $base-color-light;
            }
            @media (max-width:991px) {
                background: $base-color-light;
                color: $base-color-dark;
                &::placeholder {
                    color: $base-color-dark;
                }
            }
        }
    }
}

.cart form {
    max-width: 100vw;

    label {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.newsletter form {
    width: 100%;
    .form-group-newsletter {
        input {
            padding: 1.125rem 1.5rem;
            border: none;
        }
    }
}

.registration form {
    .error-wrap {
        color: $special-color;
        font-size: .9rem;
        margin-top: 0.3rem;
    }
    input,
    textarea {
        background: transparent;
        border: 1px solid $base-color-light;
        color: $base-color-light;
        font-weight: 700;
        &::placeholder {
            color: inherit;
            font-weight: inherit;
        }
        &.is-invalid {
            border: 1px solid $special-color;
        }
        &.is-valid {
            border: 1px solid $primary-color;
        }
        &.text-right {
            text-align: right;
        }
    }
    input {
        &[type="file"] {
            display: none;
        }
    }
    .file-form-wrapper {
        cursor: pointer;
        font-weight: 700;
        color: $base-color-light;
        border: 1px solid $base-color-light;
        padding: 0.75rem 2.5rem 0.9375rem 1rem;
        display: block;
        width: 100%;
        height: 100%;
        max-height: 10.09rem;
    }
    .checkbox-label {
        &.is-invalid {
            color: $special-color;
        }
    }
}


input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
