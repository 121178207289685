.tooltip-box {
    position: relative;

    &:hover {
        cursor: pointer;
        .tooltip {
            display: block;
        }
    }
    .tooltip {
        background: rgba($base-color-light, .9);
        color: $base-color-dark;
        //font-weight: bold;
        //padding: 5px 10px;
        display: none;
        font-size: .7rem;
        border-radius: 4px;
        position: absolute;
        width: 25rem;
        max-width: 100vw;
        z-index: 999;
        left: calc(100% + 15px);
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        .tooltip-arrow,
        .tooltip-arrow::before {
            position: absolute;
            width: 10px;
            height: 10px;
            top: 50%;
            left: -2px;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            background: $base-color-light;
        }

        .tooltip-arrow {
            visibility: hidden;
        }

        .tooltip-arrow::before {
            visibility: visible;
            content: '';
            transform: rotate(45deg);
        }
    }

}

