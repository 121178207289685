@charset "UTF-8";
@use "sass:math";

@import 'import/intl-tel-input';

@import "bootstrap/bootstrap-custom";


// 1. Configuration and helpers
@import "abstracts/variables";
@import "abstracts/mixins";

// 2. Vendor
@import "vendor/normalize";
@import "vendor/toastify";

// 3. Base
@import "base/reset";
@import "base/base";
@import "base/fonts";
@import "base/typography";


// 4. Layout
@import "layout/header";
@import "layout/main";
@import "layout/footer";
@import "layout/columns";

// 5. Components
@import "components/customer_order";
@import "components/button";
@import "components/form";
@import "components/checkbox";
@import "components/card";
@import "components/gallery";
@import "components/banner";
@import "components/newsletter";
@import "components/about";
@import "components/registration";
@import "components/product-detail";
@import "components/filter";
@import "components/loader";
@import "components/pagination";
@import "components/avatar";
@import "components/modal";
@import "components/header";
@import "components/tooltip";
@import "components/badge";
@import "components/swiper";
@import "components/list";


@import "cart/cart-icon";
@import "cart/cart";
@import "cart/cart-steps";
@import "cart/cart_summary";

@import "~nice-select2/dist/css/nice-select2.css";
