$checkbox-size: 1.125rem;

.checkbox {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    .checkbox-label {
        position: relative;
        display: inline-block;
        padding-left: $checkbox-size * 1.5;
        text-align: left;
        line-height: 1.2;

        &__link,
        a {
            display: inline-block;

            text-decoration: underline;
            color: $tertiary-color;
            font-weight: 700;
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: $checkbox-size;
            width: $checkbox-size;
            opacity: 0;
            background-image: url("../../img/icons/check-icon.svg");
            background-position: center;
            background-size: 0.75rem;
            background-repeat: no-repeat;
            transition: opacity 0.3s;
            transform: translateY(-55%);
            z-index: 1;
        }

        &:after {
            content: " ";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: $checkbox-size;
            width: $checkbox-size;
            background: $base-color-light;
            border: 1px solid #e7e7e7;
            border-radius: 3px;
            transform: translateY(-55%);
            transition: background-color 0.3s;
        }

        &.without-checkbox {

        }
    }

    input {
        position: absolute;
        top: 50%;
        height: $checkbox-size;
        width: $checkbox-size;
        opacity: 0;
        transform: translateY(-50%);
        transition: all 0.3s;
    }

    input:checked ~ .checkbox-label:before {
        opacity: 1;
    }
    input:checked ~ .checkbox-label:after {
        background: $base-color-light;
    }
    input:focus ~ .checkbox-label:after {
        border-color: $secondary-color;
    }

    input.required ~ .checkbox-label:after {
        border-color: #f44336;
        box-shadow: 0 0 0 0.2rem rgba(#f44336, 0.25);
    }
}

$checkbox-register-size: 1.5rem;
.checkbox-register {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    .checkbox-label {
        font-size: 1rem;
        position: relative;
        display: inline-block;
        padding-left: $checkbox-register-size * 1.5;
        text-align: left;
        line-height: 1.2;
        color: $text-color--light;

        &__link,
        a {
            font-size: 1rem;
            display: inline-block;

            text-decoration: underline;
            color: $text-color--light;
            font-weight: 700;
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: $checkbox-register-size;
            width: $checkbox-register-size;
            opacity: 0;
            background-image: url("../../img/icons/check-icon.svg");
            background-position: center;
            background-size: 0.75rem;
            background-repeat: no-repeat;
            transition: opacity 0.3s;
            transform: translateY(-55%);
            z-index: 1;
        }

        &:after {
            content: " ";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: $checkbox-register-size;
            width: $checkbox-register-size;
            background: transparent;
            border: 1px solid #e7e7e7;
            transform: translateY(-55%);
            transition: background-color 0.3s;
        }

        &.without-checkbox {

        }
    }

    input {
        position: absolute;
        top: 50%;
        height: $checkbox-register-size;
        width: $checkbox-register-size;
        opacity: 0;
        transform: translateY(-50%);
        transition: all 0.3s;
    }

    input:checked ~ .checkbox-label:before {
        opacity: 1;
    }
    input:checked ~ .checkbox-label:after {
        background: $text-color--light;
    }
    input:focus ~ .checkbox-label:after {
        border-color: $text-color--light;
    }

    input.required ~ .checkbox-label:after {
        border-color: #f44336;
        box-shadow: 0 0 0 0.2rem rgba(#f44336, 0.25);
    }
}
