.newsletter {
    background: url("../../img/bg.jpg") no-repeat;
    background-size: cover;
    background-position: top center;
    .checkbox-label {
    	font-size: .7rem;
    	line-height: .75rem;
    	color: $base-color-light;
    	font-weight: 700;
    	@media (max-width: 576px) {
    		font-size: .6rem;
    	}
    	a {
    		font-size: inherit;
    		color: inherit;
    		text-decoration: underline;
    		&:hover {
    			text-decoration: none;
    		}
    	}
    }

    .form-group-newsletter {
        gap: 1.25rem;
        @media (max-width: 576px) {
            gap: 0.625rem;
        }
    }
}
