// -----------------------------------------------------------------------------
// This file contains base reset styles
// -----------------------------------------------------------------------------

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

img {
    vertical-align: middle;
}

address {
    font-style: normal;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
