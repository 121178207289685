footer {
    background-color: $base-color-dark;
    color: #fff;
    .footer-menu {
        list-style: none;
        margin: 0.5rem 0 0;
        padding: 0;
        color: $base-color;
		&__link {
			color: $base-color;
			display: inline-block;
			padding: .3rem 0;
			&:hover {
				text-decoration: underline;
			}
		}
		&__title {
			display: inline-block;
			font-size: 1.125rem;
			font-weight: 700;
			@media (max-width: 576px) {
				font-size: 1rem;
			}
			&.text-uppercase {
				text-transform: uppercase;
				font-size: .875rem;
				line-height: 1.3rem;
			}
		}
		&__address {
			padding: .3rem 0;
		}
		&__item--social {
			padding: .5rem 0;
		}
		&__icon {
			margin-right: .5rem;
			display: inline-block;
		}
    }
    .footer-copyright {
        border-top: 1px solid $base-color;
        .copyright-text {
            padding: 1rem 0;
            text-align: center;
			color: $base-color;
        }
    }
}
