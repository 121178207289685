.avatar-wrap {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 50%;

    .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.avatar-lg {
        width: 9rem;
        height: 9rem;
    }
}
