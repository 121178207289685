.card {
    position: relative;
    text-align: center;
    cursor: pointer;
    &:hover {
        .card-header {
            background: transparent;
            img {
                transform: scale(1.05);
            }
            .card-price {
                opacity: 0;
                transform: translateX(-25px);
            }
        }
        .card-body {
			position: relative;
			transform: translateY(-80px);
        }
    }
    .card-header {
        background: #f8f8f8;
        padding: 1.375rem;
        .card-price {
            font-weight: 700;
            font-size: 36px;
            display: inline-block;
            padding: 0.9375rem 0 0;
            @media (max-width: 576px) {
                font-size: 1.5rem;
                line-height: 2.25rem;
            }
        }
    }

    .img-eco {
        top: -10px;
        position: absolute;
        left: -10px;
        max-width: 5.5rem;
        max-height: 5.5rem;
        width: 100%;
        height: auto;
        z-index: 3;
    }
    &.card-game {
        height: 100%;

        .card-header {
            height: 12rem;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &:hover {
            .card-body {
                background-color: rgba($base-color-light, .95);
            }
        }

    }

    .card-title {
        font-size: 1.125rem;
        line-height: normal;
        margin-bottom: 0.75rem;

        a {
            font-size: inherit;
            line-height: inherit;
        }
    }

    .card-badge {
        position: absolute;
        z-index: 10;
        top: 0;
        right: .75rem;
        font-weight: bold;
        font-size: 1.3rem;
        padding: 1.75rem 1rem;
        color: $text-color--light;
        background-color: $base-color-dark;
        box-shadow: $box-shadow--small;
    }
}

.card * {
    transition: all 0.2s ease-in;
}

