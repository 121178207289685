.swiper {
    &.full-swiper {
        height: 25rem;
        max-height: 25rem;

        @media (max-width: 1399px) {
            h1 {
                &.breakline {
                    max-width: 35rem;
                }
            }
        }

        @media (max-width: 991px) {
            h1 {
                &.breakline {
                    max-width: 100%;
                    text-align: center;
                }
            }
        }

        .swiper-wrapper {
            max-height: inherit;
        }

        .swiper-slide {
            max-height: inherit;
        }

        .slide-wrapper {
            max-height: inherit;
            height: 100%;

            img {
                min-width: 100% !important;
                min-height: 100% !important;
                height: 100% !important;
                object-fit: cover;
                max-height: inherit;
            }
        }
    }
}
