[x-cloak] { display: none !important; }


body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: #fff;
}

main {
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
    //display: block;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.noUi-touch-area {
    &:hover {
        cursor: pointer;
    }
}

//////

.show-more-link {
    position: relative;
    padding-right: 1.25rem;
    @media (max-width: 576px) {
        padding-right: 1rem;
    }
    &:after {
        content: "";
        display: block;
        background-image: url("../../img/icons/arrow-right.svg");
        background-repeat: no-repeat;
        position: absolute;
        top: 5px;
        right: 0px;
        width: 8px;
        height: 16px;
    }
}

body {
    font-family: "Inter", sans-serif;
}

.object-cover {
    object-fit: cover;
}

.object-contain {
    object-fit: contain;
}

.invisible {
    visibility: hidden;
}

.-z-50 {
    z-index: -50;
}

.z-10 {
    z-index: 10;
}

.top-0-5-rem {
    top: .5rem;
}

.top--1rem {
    top: -1rem;
}
.top--1-5rem {
    top: -1.5rem;
}
.left--1rem {
    left: -1rem;
}
.left--1-5rem {
    left: -1.5rem;
}

.right-0 {
    right: 0;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.font-weight-normal {
    font-weight: normal;
}

.bg-light {
    background-color: $base-color-light;
}

.text-light {
    color: $base-color-light;
}

.text-right {
    text-align: right;
}
//.text-dark {
//    color: $base-color-light;
//}

.text-error {
    color: $special-color;
    font-size: 0.9rem;
}

.hover-cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

.w-100 {
    width: 100%;
}

.w-max-content {
    width: max-content;
}


.error-wrap {
    color: $special-color;
    font-size: 0.9rem;
    margin-top: 0.3rem;
}

.border-top {
    border-top: 1px solid $base-color;
};

input {
    &.is-invalid {
        border: 1px solid $special-color;
    }
    &.is-valid {
        border: 1px solid $primary-color;
    }
}

.h-full {
    height: 100%;
}

.box-shadow {
    box-shadow: $box-shadow;
}
.box-shadow-medium {
    box-shadow: $box-shadow--medium;
}
.box-shadow-small {
    box-shadow: $box-shadow--small;
}

.hover-size-up {
    transition: all .3s;

     &:hover {
         transform: scale(1.05);
     }
}

.image-wrap {
    max-width: 5rem;
    max-height: 5rem;
    height: 100%;
    width: 100%;
    overflow: hidden;


    &.no-overflow {
        overflow: visible;
    }
     &.wrap--sm {
         max-width: 3rem;
         max-height: 3rem;
     }

    &.wrap--md {
        max-width:  7rem;
        max-height:  7rem;
    }
    &.wrap--lg {
        max-width: 9rem;
        max-height: 9rem;
    }
    &.wrap--xl {
        max-width:11rem;
        max-height: 11rem;
    }

    img {
        max-height: inherit;
        max-width: inherit;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}
