.catalog-header {
    text-align: center;
}

.catalog-filter {
    background: $base-color-dark;
	position: sticky;
    top: 0;
    z-index: 15;
    form {
        max-width: 100%;
    }
}

.dropdown {
    color: $base-color-light;
    position: relative;
    .dropdown-label {
        cursor: pointer;
        color: $base-color-light;
        display: block;
        padding: 1.25rem 0 1.25rem 0.625rem;
    }
    .dropdown-list {
        position: absolute;
        top: 4rem;
        left: 0;
        z-index: 20;
        background: $base-color-dark;
        min-width: 14rem;
        max-height: 19rem;
        overflow-y: auto;

        .accordion-content {
            list-style-type: none;
            display: none;
            &.active {
                display: block;
            }
            li {
                padding-left: 0.7rem;
            }
        }

        .accordion-content-active {
            list-style-type: none;
            li {
                padding-left: 0.7rem;
            }
        }

        .caret-wrap {
            &:hover {
                cursor: pointer;
            }
            min-width: 3rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.2rem;
                color: $base-color-light;
                .arrow-down {
                    transform: rotate(90deg);
                }
            }
        }


        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        .li-padding {
            padding: 0.65rem 0.95rem;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            li {
                &.selected {
                    background: lighten(#2f2f2f, 10%);
                }
                &:hover {
                    background: #2f2f2f;
                }
                &.no-hover {
                    &:hover {
                        background-color: transparent !important;
                    }
                }
                .hover-li {
                    &:hover {
                        background-color: #2f2f2f;
                    }
                }
                .checkbox {
                    padding: 0.65rem 0.95rem;
					width: 100%;
                    label {
                        display: block;
                        cursor: pointer;
                        white-space: nowrap;
                        input {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        &.collapse {
            display: none;
        }
        &.show {
            display: block;
        }
    }
}

.dropdown-group {
    display: flex;
    @media (max-width: 991px) {
        display: none;
    }
}
.dropdown-group.mobile-fixed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: -webkit-fill-available;
    width: 100%;
    background: $base-color-light;
    z-index: 10;
    top: 70px;
    left: 0;
    overflow: auto;
    margin-bottom: 70px;
    .dropdown {
        color: $base-color-dark;
        border-bottom: 1px solid #ddd;
        .dropdown-label {
            color: $base-color-dark;
        }
        .dropdown-list {
            position: relative;
            top: 0;
            background: $base-color-light;
            li {
                &:hover {
                    background-color: #e7e7e7;
                }
                &.no-hover {
                    &:hover {
                        background-color: transparent !important;
                    }
                }
            }
            .hover-li {
                &:hover {
                    background-color: #e7e7e7;
                }
            }
        }
    }
}

.dropdown-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 25;
}
