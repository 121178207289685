.banner {
	background: linear-gradient(89deg, rgb(15 15 15) 48%, rgba(242, 242, 242, 0.0340511204) 164%);
	position: relative;
	img {
		@media (max-width: 991px) {
			max-width: unset;
			position: relative;
			right: 40px;
		}
	}
}

.banner.become-designer {
	background: url('../../img/banner-img-1.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.banner.how-it-works {
	background: url('../../img/banner-img-2.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
	padding: 2rem 0;
}