.pagination {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
    justify-content: center;
    //border-bottom: 1px solid $base-color-dark;
    //border-top: 1px solid $base-color-dark;
}

.page-link {
    font-family: $text-font;
    position: relative;
    display: block;
    padding: 0.8rem 1.2rem;
    color: $base-color-dark;
    text-decoration: none;
    background-color: $base-color-light;
    transition: all .3s;

    &:hover {
        z-index: 2;
        text-decoration: none;
        color: $base-color-light;
        background-color: $base-color-dark;
        cursor: pointer;
    }

    &:focus {
        z-index: 3;
        outline: 0;
        box-shadow: none;
    }
}

.page-item {
    border: 1px solid rgba(15, 15, 15, 0.9);
    &:not(:last-child) {
        //border-right: 1px solid $base-color-dark;
    }

    &:first-child {
        .page-link {
            margin-left: 0;
            border-radius: 0;
        }
    }
    &:last-child {
        .page-link {
            border-radius: 0;
        }
    }

    &.active .page-link {
        z-index: 3;
        color: $base-color-light;
        background-color: rgba($base-color-dark, .9);
        height: 100%;
        width: 100%;
    }

    &.disabled .page-link {
        pointer-events: none;
        cursor: auto;
        color: $base-color-light;
        background-color: lighten($base-color-dark, 30%);
        height: 100%;
        width: 100%;
    }
}
