.catalog-header {
    &.game-header {

        position: relative;
        z-index: 1;
        color: $base-color-light;


        .game-header__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            &:after {
                content: "";
                background-color: rgba(0,0,0,.3);
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .game-header__img {
            opacity: .9;
            position: relative;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            z-index: -1;
        }
    }
}
