.product-detail {
	position: relative;
    .product-detail-information {
		.designer-name {
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
        .price {
            color: #ff3f3f;
        }
        .product-count {
			border: 1px solid #e5e5e5;
			border-radius: 8px;
			font-size: 22px;
			width: fit-content;
			padding: .3125rem 1.25rem;
            input {
                border: none;
				padding: 0;
				width: 30px;
				text-align: center;
            }
			.plus, .minus {
				cursor: pointer;
				user-select: none;
			}
        }
        .color-options {
            gap: 1.25rem;
            .color-radio {
                input {
                    display: none;
                    &:checked + .radio-label {
                        border: 1px solid $base-color-dark;
                    }
                }
                .radio-label {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;
                    .inner-color {
                        display: block;
                        width: 24px;
                        height: 24px;
                        //background: #f178b6;
                    }
                }
            }
        }
        .size-options {
            gap: 1.25rem;
            .size-radio {
               input {
                    display: none;
                    &:checked + .radio-label {
                        font-weight: 700;
                        border: 1px solid $base-color-dark;
                    }
                }
                .radio-label {
                    border: 1px solid lighten(#e5e5e5, 5%);
                    border-radius: 8px;
                    height: 36px;
                    display: flex;
                    width: 36px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
					//min-width: 22px;
					text-align: center;
                    //padding: 6px;
                }
            }
        }
    }
}

.container-special {
	.col-special {
		position: absolute;
		left: 0;
		height: 100%;
		padding: 0;
		@media (max-width: 991px) {
			position: relative;
		}
	}
	.col-special-margin {
		margin-left: auto;
		margin-right: 0;
		@media (max-width: 991px) {
			margin-left: 0;
		}
	}
}
